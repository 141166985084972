import * as React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../components/layout';
import { Seo, HeadMeta } from '../components/layout/meta';

const NotFoundPage = () => (
  <>
    <Seo title={`Page not found`} />
    <Layout wpPage={'404'}>
      <div className="page-404">
        <h1 className="page-404__title">404: Not Found</h1>
        <div className="page-404__content">
          <p>Sorry we couldn’t find what you were looking for!</p>
        </div>
        <Link to="/" className="page-404__link">
          Go home
        </Link>
      </div>
    </Layout>
  </>
);

export default NotFoundPage;

export const Head = () => <HeadMeta />;
